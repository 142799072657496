import { gql } from '@apollo/client';

const TABLES_QUERY = gql`
    query Tables {
        entities {
            code
            name
        }
    }
`;

export { TABLES_QUERY };
export default TABLES_QUERY;
