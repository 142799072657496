import { NextPage } from 'next';
import { FC } from 'react';

type WithLayout = (WrappedComponent: NextPage | FC, Layout: FC) => FC;

const withLayout: WithLayout = (WrappedComponent, Layout) => {
    const NewComponent: FC = () => {
        return (
            <Layout>
                <WrappedComponent />
            </Layout>
        );
    };
    return NewComponent;
};

export { withLayout };
export default withLayout;
