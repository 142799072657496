import {
    AppBar,
    Button,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeftOutlined, HomeOutlined, MenuOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouterLink } from '@/components/controls';
import { useApolloLoading, useUser } from '@/hooks';
import { useSelector } from '@/store';
import { toggleDrawer } from '@/store/slices/dashboard.slice';
import { getRouteByName } from '@/utils';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    loader: {
        marginLeft: theme.spacing(2),
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    logout: {
        marginLeft: 'auto',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const DashboardLayout: FC = ({ children }) => {
    const classes = useStyles();

    const { fetching } = useApolloLoading();

    const dispatch = useDispatch();

    const { asPath, push, pathname } = useRouter();

    const { signOut } = useUser();

    const { isDrawerOpen, drawerLinks } = useSelector((store) => store.dashboard);

    const handleToggleDrawer = () => dispatch(toggleDrawer(!isDrawerOpen));

    const handleGoHome = () => push('/dashboard');

    const handleLogout = async () => {
        const result = await signOut();
        if (result) push('/auth');
    };

    useEffect(() => {
        if (isDrawerOpen) dispatch(toggleDrawer(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asPath]);

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isDrawerOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Открыть сайдбар"
                        onClick={handleToggleDrawer}
                        edge="start"
                        className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
                    >
                        <MenuOutlined />
                    </IconButton>
                    {asPath !== '/dashboard' && (
                        <IconButton color="inherit" aria-label="Домой" onClick={handleGoHome} edge="start">
                            <HomeOutlined />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap>
                        Административная панель
                    </Typography>
                    {fetching && <CircularProgress color="secondary" className={classes.loader} size={30} />}
                    <Button variant="outlined" color="inherit" className={classes.logout} onClick={handleLogout}>
                        Выход
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleToggleDrawer}>
                        <ChevronLeftOutlined />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {drawerLinks.map((routeName) => {
                        const route = getRouteByName(routeName);
                        return (
                            <ListItem key={route.name} button component={RouterLink} href={route.path}>
                                {!!route.icon && (
                                    <ListItemIcon>
                                        <route.icon />
                                    </ListItemIcon>
                                )}
                                <ListItemText primary={route.title} />
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: isDrawerOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                {children}
            </main>
        </div>
    );
};

export { DashboardLayout };
export default DashboardLayout;
