import { gql } from '@apollo/client';

export const ENTITIES_UPDATE_MUTATION = gql`
    mutation EntitiesUpdate($input: AdminInputEntitiesUpdateInput!) {
        entitiesUpdate(input: $input) {
            extra {
                code
                message
                id
            }
        }
    }
`;

export default ENTITIES_UPDATE_MUTATION;
