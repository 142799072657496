import { Link } from '@material-ui/core';
import NextLink, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, forwardRef } from 'react';

export type RouteLinkProps = LinkProps & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'className'>;

const RouterLink = forwardRef<HTMLAnchorElement, RouteLinkProps>(({ className, children, ...otherProps }, ref) => {
    return (
        <NextLink {...otherProps} passHref>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className={className} ref={ref}>
                {children}
            </Link>
        </NextLink>
    );
});

RouterLink.displayName = 'RouterLink';

export { RouterLink };
export default RouterLink;
