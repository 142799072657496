import { gql } from '@apollo/client';

export const ADD_ANTITY_MUTATION = gql`
    mutation AddEntity($input: AdminInputEntityAddInput!) {
        entityAdd(input: $input) {
            extra {
                code
                message
            }
        }
    }
`;

export default ADD_ANTITY_MUTATION;
