import { fromUnixTime, getUnixTime } from 'date-fns';
import { useCallback } from 'react';
import { AnyOfTableColumnTypes, ApiTableColumn, FieldMeta, TableColumn, TableColumnType } from 'types/tables.types';

import { AdminEnumDataTypeEnum } from '@/apollo/types';

type GetValueForTable = <T extends TableColumn<AnyOfTableColumnTypes>>(
    column: T,
    value: string
) => T['type'] extends TableColumnType.BOOLEAN
    ? boolean
    : T['type'] extends TableColumnType.DATE_TIME
    ? Date
    : T['type'] extends TableColumnType.INTEGER
    ? number
    : T['type'] extends TableColumnType.FLOAT
    ? number
    : string;

type TransformEntityDataToColumns = (entity: ApiTableColumn) => TableColumn<AnyOfTableColumnTypes>;

type GetValueForApi = (meta: FieldMeta, value: string | number | boolean | Date) => string[];

type GetFormatterFnForTable = (meta: FieldMeta) => (value: string) => string;

type GetTableFieldType = (column: TableColumn<AnyOfTableColumnTypes>) => 'boolean' | 'dateTime' | 'number' | 'string';

type UseTableUtils = () => {
    getValueForTable: GetValueForTable;
    getValueForApi: GetValueForApi;
    getTableFieldType: GetTableFieldType;
    transformEntityDataToColumns: TransformEntityDataToColumns;
};

const useTableUtils: UseTableUtils = () => {
    const getValueForTable: GetValueForTable = useCallback((column, value) => {
        switch (column.type) {
            case TableColumnType.BOOLEAN:
                return value === 'true';
            case TableColumnType.DATE_TIME: {
                return fromUnixTime(Number(value));
            }
            case TableColumnType.INTEGER:
                return parseInt(value, 10);
            case TableColumnType.FLOAT:
                return parseFloat(value);
            default:
                return (value ?? '').toString();
        }
    }, []) as GetValueForTable;

    const getValueForApi: GetValueForApi = useCallback((meta, value) => {
        const result = meta.isMultiple ? value.toString().split(',') : [value];
        return result.map((v) => {
            switch (meta.type) {
                case AdminEnumDataTypeEnum.Datetime:
                    return new Date(v.toString()).getTime().toString();
                case AdminEnumDataTypeEnum.Integer:
                    return parseInt(v.toString(), 10).toString();
                default:
                    return v.toString();
            }
        });
    }, []);

    const getFormatterFnForTable: GetFormatterFnForTable = useCallback((meta) => {
        if (meta.type === AdminEnumDataTypeEnum.Datetime) return (value) => new Date(value).toLocaleDateString('ru');
        return (value) => value.toString();
    }, []);

    const getTableFieldType: GetTableFieldType = useCallback((column) => {
        switch (column.type) {
            case TableColumnType.BOOLEAN:
                return 'boolean';
            case TableColumnType.DATE_TIME:
                return 'dateTime';
            case TableColumnType.INTEGER || TableColumnType.FLOAT:
                return 'number';
            default:
                return 'string';
        }
    }, []);

    const transformApiType = (type: AdminEnumDataTypeEnum): TableColumnType => {
        switch (type) {
            case AdminEnumDataTypeEnum.Boolean:
                return TableColumnType.BOOLEAN;
            case AdminEnumDataTypeEnum.Datetime:
                return TableColumnType.DATE_TIME;
            case AdminEnumDataTypeEnum.Integer:
                return TableColumnType.INTEGER;
            default:
                return TableColumnType.STRING;
        }
    };

    const transformEntityDataToColumns: TransformEntityDataToColumns = useCallback(
        (entity) => ({
            code: entity.code,
            name: entity.name,
            defaultValue: entity.defaultValue.length ? entity.defaultValue[0] ?? '' : '',
            modifiers: {
                isEditable: entity.isEditable,
                isRequired: entity.isRequired,
            },
            resultFormatter:
                entity.type === AdminEnumDataTypeEnum.Datetime
                    ? (value) => getUnixTime(new Date((value ?? '').toString())).toString()
                    : (value) => (value ?? '').toString(),
            type: transformApiType(entity.type),
        }),
        []
    );

    return {
        getValueForTable,
        getValueForApi,
        getFormatterFnForTable,
        getTableFieldType,
        transformEntityDataToColumns,
    };
};

export { useTableUtils };
export default useTableUtils;
