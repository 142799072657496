import { GridCellValue } from '@mui/x-data-grid';
import { Maybe } from 'graphql/jsutils/Maybe';

import { AdminEnumDataTypeEnum } from '@/apollo/types';

export interface FieldMeta {
    defaultValue: Array<Maybe<string>>;
    isEditable: boolean;
    field: string;
    isMultiple: boolean;
    isRequired: boolean;
    type: AdminEnumDataTypeEnum;
    name: string;
    code: string;
}

export type TableRowType = GridCellValue;

export enum TableColumnType {
    INTEGER = 'INTEGER',
    FLOAT = 'FLOAT',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    DATE_TIME = 'DATE_TIME',
}

export interface TableColumnModifiers {
    isRequired: boolean;
    isEditable: boolean;
}

export type TableRow = {
    [code: string]: TableRowType;
};

export type AnyOfTableColumnTypes =
    | TableColumnType.STRING
    | TableColumnType.BOOLEAN
    | TableColumnType.DATE_TIME
    | TableColumnType.FLOAT
    | TableColumnType.INTEGER;

export type TableColumn<
    T extends TableColumnType,
    D = T extends TableColumnType.BOOLEAN
        ? boolean
        : T extends TableColumnType.FLOAT
        ? number
        : T extends TableColumnType.INTEGER
        ? number
        : T extends TableColumnType.DATE_TIME
        ? Date
        : string
> = {
    type: T;
    name: string;
    code: string;
    resultFormatter: (value: GridCellValue) => string;
    defaultValue: D;
    modifiers: TableColumnModifiers;
};

export type TableRows = TableRow[];

export type TableColumns = TableColumn<AnyOfTableColumnTypes>[];

export interface ApiTableColumn {
    name: string;
    code: string;
    type: AdminEnumDataTypeEnum;
    isMultiple: boolean;
    defaultValue: Array<Maybe<string>>;
    isRequired: boolean;
    isEditable: boolean;
}
