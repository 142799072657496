import { useEffect, useState } from 'react';

const useLastTruthyValue = <T>(value: T): T => {
    const [lastValue, setLastValue] = useState<T>(value);

    useEffect(() => {
        if (value) setLastValue(value);
    }, [value]);

    return lastValue;
};

export { useLastTruthyValue };
export default useLastTruthyValue;
