import { useEffect, useState } from 'react';

import { useApolloNetworkStatus } from '@/apollo/client';

type UseApolloLoadingHook = () => { fetching: boolean };

const useApolloLoading: UseApolloLoadingHook = () => {
    const [fetching, setFetching] = useState(false);

    const status = useApolloNetworkStatus();

    const isApolloLoading = status.numPendingQueries > 0 || status.numPendingMutations > 0;

    useEffect(() => {
        setFetching(isApolloLoading);
    }, [isApolloLoading]);

    return { fetching };
};

export { useApolloLoading };
export default useApolloLoading;
