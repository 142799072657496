import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum AdminEnumDataTypeEnum {
    /** да/нет */
    Boolean = 'BOOLEAN',
    /** дата со временем (timestamp в секундах) */
    Datetime = 'DATETIME',
    /** число с плавающей точкой */
    Float = 'FLOAT',
    /** целое число */
    Integer = 'INTEGER',
    /** строка */
    String = 'STRING',
}

export enum AdminEnumFilterConditionEnum {
    /** строгое сравнение */
    Equals = 'EQUALS',
    /** => */
    GreaterEq = 'GREATER_EQ',
    /** <= */
    LessEq = 'LESS_EQ',
    /** поиск вхождения строки */
    Like = 'LIKE',
}

export enum AdminEnumSortOrderEnum {
    /** по возрастанию */
    Asc = 'ASC',
    /** по убыванию */
    Desc = 'DESC',
}

export type AdminInputEntitiesUpdateInput = {
    /** символьный код таблицы */
    code: Scalars['String'];
    /** значения полей */
    data: Array<AdminInputEntityElementInput>;
};

export type AdminInputEntityAddInput = {
    /** символьный код таблицы */
    code: Scalars['String'];
    /** значения полей */
    values: Array<AdminInputEntityFieldValueInput>;
};

export type AdminInputEntityDataFilterInput = {
    /** условие */
    condition: AdminEnumFilterConditionEnum;
    /** символьный код поля */
    fieldCode: Scalars['String'];
    /** значения поля */
    value: Scalars['String'];
};

export type AdminInputEntityDataInput = {
    /** код сущности */
    entityCode: Scalars['String'];
    /** фильтры */
    filters?: Maybe<Array<AdminInputEntityDataFilterInput>>;
    /** кол-во возвращаемых записей */
    limit: Scalars['Int'];
    /** смещение от начала */
    offset: Scalars['Int'];
    /** сортировка */
    sort?: Maybe<Array<AdminInputSortInput>>;
};

export type AdminInputEntityElementInput = {
    /** id записи */
    id: Scalars['Int'];
    /** значения полей */
    values: Array<AdminInputEntityFieldValueInput>;
};

export type AdminInputEntityFieldValueInput = {
    /** символьный код поля */
    fieldCode: Scalars['String'];
    /** значения */
    values: Array<Scalars['String']>;
};

export type AdminInputEntityUpdateInput = {
    /** символьный код таблицы */
    code: Scalars['String'];
    /** значения полей */
    data: AdminInputEntityElementInput;
};

export type AdminInputSortInput = {
    /** код поля для сортировки */
    code: Scalars['String'];
    /** направление сортировки */
    order: AdminEnumSortOrderEnum;
};

export type AdminTypeEntityAddType = {
    /** ошибки */
    extra: Array<AdminTypeEntityAddTypeExtra>;
    /** id созданной записи */
    id?: Maybe<Scalars['Int']>;
};

export enum AdminTypeEntityAddTypeErrors {
    /** Ошибка входных данных */
    InvalidInput = 'INVALID_INPUT',
    /** Системная ошибка, попробуйте позже */
    SystemError = 'SYSTEM_ERROR',
    /** Сущность уже существует */
    UniqueEntityExist = 'UNIQUE_ENTITY_EXIST',
}

export type AdminTypeEntityAddTypeExtra = {
    /** код ошибки */
    code: AdminTypeEntityAddTypeErrors;
    /** текст ошибки */
    message: Scalars['String'];
};

export type AdminTypeEntityDataType = {
    /** выбранные данные */
    data: Array<AdminTypeEntityFieldValuesType>;
    /** информация о сущности */
    entity: AdminTypeEntityType;
    /** общее количество всех записей */
    total: Scalars['Int'];
};

export type AdminTypeEntityDeleteAllType = {
    /** ошибки */
    extra: Array<AdminTypeEntityDeleteAllTypeExtra>;
};

export enum AdminTypeEntityDeleteAllTypeErrors {
    /** Ошибка входных данных */
    InvalidInput = 'INVALID_INPUT',
    /** Системная ошибка, попробуйте позже */
    SystemError = 'SYSTEM_ERROR',
}

export type AdminTypeEntityDeleteAllTypeExtra = {
    /** код ошибки */
    code: AdminTypeEntityDeleteAllTypeErrors;
    /** текст ошибки */
    message: Scalars['String'];
};

export type AdminTypeEntityDeleteType = {
    /** ошибки */
    extra: Array<AdminTypeEntityDeleteTypeExtra>;
};

export enum AdminTypeEntityDeleteTypeErrors {
    /** Сущность не найдена */
    EntityNotExist = 'ENTITY_NOT_EXIST',
    /** Ошибка входных данных */
    InvalidInput = 'INVALID_INPUT',
    /** Системная ошибка, попробуйте позже */
    SystemError = 'SYSTEM_ERROR',
}

export type AdminTypeEntityDeleteTypeExtra = {
    /** код ошибки */
    code: AdminTypeEntityDeleteTypeErrors;
    /** id сущности, которая вызвало ошибку */
    id?: Maybe<Scalars['Int']>;
    /** текст ошибки */
    message: Scalars['String'];
};

export type AdminTypeEntityFieldType = {
    /** код */
    code: Scalars['String'];
    /** значение по умолчанию */
    defaultValue: Array<Maybe<Scalars['String']>>;
    /** редактируемое */
    isEditable: Scalars['Boolean'];
    /** тип поля */
    isMultiple: Scalars['Boolean'];
    /** обязательное поле */
    isRequired: Scalars['Boolean'];
    /** название */
    name: Scalars['String'];
    /** тип поля */
    type: AdminEnumDataTypeEnum;
};

export type AdminTypeEntityFieldValueType = {
    /** код поля сущности */
    fieldCode: Scalars['String'];
    /** значения поля сущности */
    value: Array<Scalars['String']>;
};

export type AdminTypeEntityFieldValuesType = {
    /** список значений */
    values: Array<AdminTypeEntityFieldValueType>;
};

export type AdminTypeEntityType = {
    /** код */
    code: Scalars['String'];
    /** список полей */
    fields: Array<AdminTypeEntityFieldType>;
    /** название */
    name: Scalars['String'];
};

export type AdminTypeEntityUpdateType = {
    /** ошибки */
    extra: Array<AdminTypeEntityUpdateTypeExtra>;
};

export enum AdminTypeEntityUpdateTypeErrors {
    /** Сущность не найдена */
    EntityNotExist = 'ENTITY_NOT_EXIST',
    /** Ошибка входных данных */
    InvalidInput = 'INVALID_INPUT',
    /** Системная ошибка, попробуйте позже */
    SystemError = 'SYSTEM_ERROR',
    /** Сущность уже существует */
    UniqueEntityExist = 'UNIQUE_ENTITY_EXIST',
}

export type AdminTypeEntityUpdateTypeExtra = {
    /** код ошибки */
    code: AdminTypeEntityUpdateTypeErrors;
    /** id сущности, которая вызвало ошибку */
    id?: Maybe<Scalars['Int']>;
    /** текст ошибки */
    message: Scalars['String'];
};

export type Mutation = {
    /** множественное удаление значений */
    entitiesDelete: AdminTypeEntityDeleteType;
    /** удаление всех записей */
    entitiesDeleteAll: AdminTypeEntityDeleteAllType;
    /** множественное изменение значений */
    entitiesUpdate: AdminTypeEntityUpdateType;
    /** добавить новое значение */
    entityAdd: AdminTypeEntityAddType;
    /** изменить значение */
    entityUpdate: AdminTypeEntityUpdateType;
    /** авторизация */
    login: Scalars['Boolean'];
    /** выход */
    logout: Scalars['Boolean'];
};

export type MutationEntitiesDeleteArgs = {
    entityCode: Scalars['String'];
    ids: Array<Scalars['Int']>;
};

export type MutationEntitiesDeleteAllArgs = {
    entityCode: Scalars['String'];
};

export type MutationEntitiesUpdateArgs = {
    input: AdminInputEntitiesUpdateInput;
};

export type MutationEntityAddArgs = {
    input: AdminInputEntityAddInput;
};

export type MutationEntityUpdateArgs = {
    input: AdminInputEntityUpdateInput;
};

export type MutationLoginArgs = {
    login: Scalars['String'];
    password: Scalars['String'];
};

export type Query = {
    /** список табличных сущностей */
    entities: Array<AdminTypeEntityType>;
    /** список записей конкретной сущности */
    entityData: AdminTypeEntityDataType;
};

export type QueryEntityDataArgs = {
    input: AdminInputEntityDataInput;
};

export type EntitiesUpdateMutationVariables = Exact<{
    input: AdminInputEntitiesUpdateInput;
}>;

export type EntitiesUpdateMutation = {
    entitiesUpdate: { extra: Array<{ code: AdminTypeEntityUpdateTypeErrors; message: string; id?: Maybe<number> }> };
};

export type AddEntityMutationVariables = Exact<{
    input: AdminInputEntityAddInput;
}>;

export type AddEntityMutation = {
    entityAdd: { extra: Array<{ code: AdminTypeEntityAddTypeErrors; message: string }> };
};

export type SignInMutationVariables = Exact<{
    login: Scalars['String'];
    password: Scalars['String'];
}>;

export type SignInMutation = { login: boolean };

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = { logout: boolean };

export type TableQueryVariables = Exact<{
    input: AdminInputEntityDataInput;
}>;

export type TableQuery = {
    entityData: {
        total: number;
        entity: {
            code: string;
            name: string;
            fields: Array<{
                name: string;
                code: string;
                type: AdminEnumDataTypeEnum;
                isMultiple: boolean;
                defaultValue: Array<Maybe<string>>;
                isRequired: boolean;
                isEditable: boolean;
            }>;
        };
        data: Array<{ values: Array<{ value: Array<string>; fieldCode: string }> }>;
    };
};

export type TablesQueryVariables = Exact<{ [key: string]: never }>;

export type TablesQuery = { entities: Array<{ code: string; name: string }> };

export const EntitiesUpdateDocument = gql`
    mutation EntitiesUpdate($input: AdminInputEntitiesUpdateInput!) {
        entitiesUpdate(input: $input) {
            extra {
                code
                message
                id
            }
        }
    }
`;
export type EntitiesUpdateMutationFn = Apollo.MutationFunction<EntitiesUpdateMutation, EntitiesUpdateMutationVariables>;

/**
 * __useEntitiesUpdateMutation__
 *
 * To run a mutation, you first call `useEntitiesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntitiesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entitiesUpdateMutation, { data, loading, error }] = useEntitiesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEntitiesUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<EntitiesUpdateMutation, EntitiesUpdateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EntitiesUpdateMutation, EntitiesUpdateMutationVariables>(EntitiesUpdateDocument, options);
}
export type EntitiesUpdateMutationHookResult = ReturnType<typeof useEntitiesUpdateMutation>;
export type EntitiesUpdateMutationResult = Apollo.MutationResult<EntitiesUpdateMutation>;
export type EntitiesUpdateMutationOptions = Apollo.BaseMutationOptions<
    EntitiesUpdateMutation,
    EntitiesUpdateMutationVariables
>;
export const AddEntityDocument = gql`
    mutation AddEntity($input: AdminInputEntityAddInput!) {
        entityAdd(input: $input) {
            extra {
                code
                message
            }
        }
    }
`;
export type AddEntityMutationFn = Apollo.MutationFunction<AddEntityMutation, AddEntityMutationVariables>;

/**
 * __useAddEntityMutation__
 *
 * To run a mutation, you first call `useAddEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntityMutation, { data, loading, error }] = useAddEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEntityMutation(
    baseOptions?: Apollo.MutationHookOptions<AddEntityMutation, AddEntityMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddEntityMutation, AddEntityMutationVariables>(AddEntityDocument, options);
}
export type AddEntityMutationHookResult = ReturnType<typeof useAddEntityMutation>;
export type AddEntityMutationResult = Apollo.MutationResult<AddEntityMutation>;
export type AddEntityMutationOptions = Apollo.BaseMutationOptions<AddEntityMutation, AddEntityMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($login: String!, $password: String!) {
        login(login: $login, password: $password)
    }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
        logout
    }
`;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
    baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const TableDocument = gql`
    query Table($input: AdminInputEntityDataInput!) {
        entityData(input: $input) {
            entity {
                code
                name
                fields {
                    name
                    code
                    type
                    isMultiple
                    defaultValue
                    isRequired
                    isEditable
                }
            }
            data {
                values {
                    value
                    fieldCode
                }
            }
            total
        }
    }
`;

/**
 * __useTableQuery__
 *
 * To run a query within a React component, call `useTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTableQuery(baseOptions: Apollo.QueryHookOptions<TableQuery, TableQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TableQuery, TableQueryVariables>(TableDocument, options);
}
export function useTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableQuery, TableQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TableQuery, TableQueryVariables>(TableDocument, options);
}
export type TableQueryHookResult = ReturnType<typeof useTableQuery>;
export type TableLazyQueryHookResult = ReturnType<typeof useTableLazyQuery>;
export type TableQueryResult = Apollo.QueryResult<TableQuery, TableQueryVariables>;
export const TablesDocument = gql`
    query Tables {
        entities {
            code
            name
        }
    }
`;

/**
 * __useTablesQuery__
 *
 * To run a query within a React component, call `useTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTablesQuery(baseOptions?: Apollo.QueryHookOptions<TablesQuery, TablesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TablesQuery, TablesQueryVariables>(TablesDocument, options);
}
export function useTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TablesQuery, TablesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TablesQuery, TablesQueryVariables>(TablesDocument, options);
}
export type TablesQueryHookResult = ReturnType<typeof useTablesQuery>;
export type TablesLazyQueryHookResult = ReturnType<typeof useTablesLazyQuery>;
export type TablesQueryResult = Apollo.QueryResult<TablesQuery, TablesQueryVariables>;
