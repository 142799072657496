import { useMutation } from '@apollo/client';

import { SIGN_IN_MUTATION, SIGN_OUT_MUTATION } from '@/apollo/documents';
import { SignInMutation, SignInMutationVariables, SignOutMutation, SignOutMutationVariables } from '@/apollo/types';

interface UseUserResult {
    signIn: (login: string, password: string) => Promise<boolean>;
    signOut: () => Promise<boolean>;
}

type UseUser = () => UseUserResult;

const useUser: UseUser = () => {
    const [signInMutation] = useMutation<SignInMutation, SignInMutationVariables>(SIGN_IN_MUTATION);

    const [signOutMutation] = useMutation<SignOutMutation, SignOutMutationVariables>(SIGN_OUT_MUTATION);

    const signIn = async (login: string, password: string) => {
        try {
            const result = await signInMutation({ variables: { login, password } });
            return Promise.resolve(!!result.data?.login);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const signOut = async () => {
        try {
            const result = await signOutMutation();
            return Promise.resolve(!!result.data?.logout);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return { signIn, signOut };
};

export { useUser };
export default useUser;
