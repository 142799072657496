import { gql } from '@apollo/client';

export const TABLE_QUERY = gql`
    query Table($input: AdminInputEntityDataInput!) {
        entityData(input: $input) {
            entity {
                code
                name
                fields {
                    name
                    code
                    type
                    isMultiple
                    defaultValue
                    isRequired
                    isEditable
                }
            }
            data {
                values {
                    value
                    fieldCode
                }
            }
            total
        }
    }
`;

export default TABLE_QUERY;
